/*
  Name    : Configuration (IP Setting, Storage User data, Logout)
  Author  : @ilhamhimura
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class myConfig {
    
  //public ApiURL:string      = 'http://127.0.0.1:8000/api/';http://27.112.78.96:7601/api/
  /*
  public ApiURL:string        = 'http://localhost:8080/smartnessApi/api/';
  public Imgurl:string        = 'http://localhost:8080/smartnessApi/imgbg';
  public SuratGenerator:string= 'http://localhost:8080/smartnessApi/surat/';
  */
  /*
  public ApiURL:string      = 'http://27.112.78.96:7601/api/';
  public Imgurl:string      = 'http://27.112.78.96:7601/imgbg';
  public SuratGenerator:string= 'http://27.112.78.96:7666/surat/';
  */
  public ApiURL:string      = 'http://202.162.195.188/api/api/';
  public Imgurl:string      = 'http://202.162.195.188/api/imgbg';
  public SuratGenerator:string= 'http://202.162.195.188/surat/';
  public userData:any;
  public keyToken:any;



  getAPIURL() {
    return this.ApiURL;
  }



  getToken() {
    if(localStorage.getItem('userSmartness') != ""){
      this.userData = JSON.parse(localStorage.getItem('userSmartness'));
      this.keyToken    = this.userData.token;
    }
    return this.keyToken;
  }

  logoutService(){
    localStorage.setItem('userSmartness', '');
    localStorage.clear();
    localStorage.removeItem('userSmartness');
    window.location.href = '#/login';
  }
}
