import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/addtugas', title: 'Buat Surat Tugas',  icon: 'ni-fat-add text-primary', class: '' },
    { path: '/addpenugasan', title: 'Buat Surat Penugasan',  icon: 'ni-fat-add text-primary', class: '' }
    
];

export const NOMORSURAT: RouteInfo[] = [
  { path: '/lapersi', title: 'Lapersi',  icon: 'ni-bullet-list-67 text-primary', class: '' },
  { path: '/tugas', title: 'Tugas',  icon: 'ni-bullet-list-67 text-primary', class: '' },
  { path: '/undangan', title: 'Undangan',  icon: 'ni-bullet-list-67 text-primary', class: '' },
  { path: '/keuangan', title: 'Keuangan',  icon: 'ni-bullet-list-67 text-primary', class: '' },
  { path: '/pelatihan', title: 'Kelengkapan Pelatihan',  icon: 'ni-bullet-list-67 text-primary', class: '' }

];
export const KEPEGAWAIAN: RouteInfo[] = [
  { path: '/kepegawaian', title: 'Kepegawaian',  icon: 'ni-circle-08 text-primary', class: '' },
  { path: '/settings', title: 'Setting',  icon: 'ni-settings-gear-65 text-primary', class: '' }

];

export const DOK: RouteInfo[] = [
  { path: '/panduan', title: 'Panduan Digital',  icon: 'ni-tv-2 text-primary', class: '' }
  
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public menuNomor: any[];
  public menuDok: any[];
  public menuPeg: any[];
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.menuNomor = NOMORSURAT.filter(menuItem => menuItem);
    this.menuDok = DOK.filter(menuItem => menuItem);
    this.menuPeg = KEPEGAWAIAN.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
